import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import { faFaceGrinHearts } from '@fortawesome/free-solid-svg-icons';


const Home = () => {

  const projects = [
    {
      image: 'car-dealership-project-image0.jpg',
    },
    {
      image: 'car-dealership-project-image1.jpg',
    },
    {
      image: 'car-dealership-project-image2.jpg',
    },
    {
      image: 'car-dealership-project-image3.png',
    },
    {
      image: 'car-dealership-project-image4.png',
    },
    {
      image: 'flag-quiz1.png',
    },
    {
       image: 'flag-quiz2.png',
    },
    {
      image: 'flag-quiz3.png',
   },

  ];
  const articles = [
    {
      title: 'Understanding serialization and DOM parsing techniques',
      link: 'https://iq.opengenus.org/dom-parsing-and-serialization-techniques/',
    },
    {
      title: 'Creating a simple website with HTML, CSS, and JavaScript for complete beginners',
      link: 'https://iq.opengenus.org/creating-a-simple-website-with-html-css-and-javascript/',
    },
    {
      title: '.ts video file format and its use in streaming services',
      link: 'https://iq.opengenus.org/ts-video-file-format/',
    },
    
  ];
  const videos = [
    {
      title: 'How to calculate a 4 digit PIN combination using JavaScript',
      link: 'https://youtu.be/hnJsEmulIJ8',
    },
    {
      title: 'How to update a React app on AWS EC2 instance  ',
      link: 'https://youtu.be/kXLKuTwT6xs',
    },
    {
      title: 'How to push your work to Github using Git bash commands',
      link: 'https://youtu.be/-QimFzEiOEA',
    },
    {
      title: 'How to schedule a Windows 10 PC shutdown via CMD commands',
      link: 'https://youtu.be/wIYCzROt11k',
    },
    {
      title: "CS50's Web programming with Python and JavaScript: Capstone",
      link: 'https://youtu.be/lHJt5eBgM_I',
    },
    {
      title: "CS50's Web programming with Python and JavaScript project4 :Network",
      link: 'https://youtu.be/LdHj89BTLKg',
    },
    {
      title: "CS50 Web programming with Python and JavaScript project 3 : Mail",
      link: 'https://youtu.be/tp-i9xMFAyM',
    },
    {
      title: "CS50 Web programming with Python and JavaScript commerce : project 2",
      link: 'https://youtu.be/Og6VUw_5XX0',
    },
    {
      title: "CS50 Web Programming with Python & JavaScript: Project1 Wiki",
      link: 'https://youtu.be/6AcWZ4RD8V4',
    },
    {
      title: "CS50 web search project0: web search",
      link: 'https://youtu.be/qmhheUZGZK4',
    },
    {
      title: "CS50x Capstone Project: E-commerce Website with Django and JavaScript",
      link: 'https://youtu.be/VY9KLraKzBQ',
    },
    
  ];



  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Open modal with selected image
  const openModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };


  return (
    <div className="container">
       <div className="row">
          <div className="col-sm">
            <section id='articles'>
            <h3>Articles</h3>
            <ul>
              {articles.map((article, index) => (
                <li key={index}>
                  <p>{article.title}</p>
                  <p>{article.summary}</p>
                  <a href={article.link} target="_blank" rel="noopener noreferrer">Read more</a>
                  <hr></hr>
                </li>              
              ))}
            </ul>
            
            </section>
            
          </div>
            <div className="col-sm">
              <section id='bio'>
                <h1>Hey, this is Bheki Daweti.</h1>
                <p>
                  I am an aspiring full stack developer. Currently doing a Software Development internship at OpenGenus, I also contribute to open source projects.
                  </p>
                    <p>
                    Check out some of my work on Github <a href='https://github.com/bhekidaweti'>repos</a>
                    <FontAwesomeIcon icon={faFaceGrinHearts} />
                    </p>
                    <hr />
              </section>
        </div>
   
          <div className="col-sm ">
            <section id='gallery'>
            <h3>Building...</h3>
              <hr></hr>
              
              {projects.map((project, index) => (
                <img 
                  key={index} 
                  src={project.image} 
                  alt={`Project ${index + 1}`} 
                  className="project-screenshot" 
                  onClick={() => openModal(project.image)}
                />
              ))}
            
            </section>
              
          </div>
          <div className="col-sm">
            <h3>YouTube Videos..</h3>
            {videos.map((video, index) => (
                          <div key={index}>
                          <a href={video.link} target="_blank" rel="noopener noreferrer">
                            {video.title}
                          </a>
                          <hr id='v-links'></hr>
                        </div>

            ))}
          </div>
          

    </div>
    {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>&times;</span>
            <img src={selectedImage} alt="Selected Project" className="full-image" />
          </div>
        </div>
      )}
  </div>
  );
}

export default Home;
